.print-table{
    
    width: 950px;
    margin: 0 auto;
    
    @include break(990){
        width: 100%;
    }
    
    .print-table-col{
        width: 100%;
        box-sizing: border-box;
        float: left;
        .print-table-th{
           text-transform: uppercase;
           border-bottom: 1px solid $grey;
           float: left;
           height: 30px;
           line-height: 30px;
           width: 100%;
           
           .col-left{
               margin: 0 0px 0 0;
               padding: 0;
               box-sizing: border-box;
               @include break(990){
                   margin: 0 0px 0 15px;
               }
           }
           .col-right{
               border: none;
           }
           
        }
        
        .col-left{
            width: 135px;
            float: left;
            padding: 0 0 0 15px;
            margin: 0;
            line-height: 18px;
            font-weight:100;
            background-color:$lightGrey;
            box-sizing: border-box;
            &.sub-product{
                padding: 0 0 0 25px;
                font-size: 13px;
                font-weight:normal;
                background-color:transparent;
            }
            @include break(900){
                display:none;
            }
            &.no-border {
                border:none;
                background-color:transparent;
            }
            &.forprinter {
                width:30%;
                padding-left:35px;
            }
        }
        
        .col-right{
            border-left: 1px solid $grey;
            float: left;
            padding: 0 0 0 20px;
            min-height: 18px;
            line-height: 18px;
            box-sizing: border-box;
            width:32%;
            background-color:$lightGrey;
            &.sub-product{
                padding: 0 0 0 55px;
                font-size: 13px;
                background-color:transparent;
            }
            &:hover {
                /*background-color:#cfcfcf;
                color:#ffffff;*/
                color:$active;
            }
            @include break(900){
                width:100%;
            }
            &.no-border {
                border:none;
                background-color:transparent;
            }
            &.forprinter {
                width:70%;
                padding-bottom:14px;
                padding-left:35px;
            }
        }

        .col-no3, .col-compat, .col-no4 {
            border-left: 1px solid $grey;
            float: left;
            padding: 0 0 0 20px;
            min-height: 18px;
            line-height: 18px;
            width:10%;
            background-color:$lightGrey;     
            &.no-border {
                border:none;
                background-color:transparent;
            }
            &.sub-product{
                font-size: 13px;
                background-color:transparent;
            }
            @include break(900){
                display:none;
            }      
        }
        
        .acc_header {
            cursor:pointer;
        }
        .col-compat {
            width:12%;
            text-align:right;
            float:right;
            box-sizing: border-box;
            padding-right:8px;
            padding-left:0px;
        }
        .col-no4 {
            width:7%;
        }
        
        .accordian_container {
            position:relative;
            overflow:hidden;
            height:1px;
            /*transition: height 0.5s ease;*/
            width:100%;
            border:0px solid $red;
        }

        .print-product{
            color: $ppgrey;
            &:hover{
                color: $hover;
            }
        }
        
        .group{
            float: left;
            clear: left;
            width: 100%;
            border-bottom: 1px solid $grey;
            padding-bottom:0px;
            h3{
                margin: 20px 0 20px 15px;
                padding: 0;
                cursor:pointer;
                .drop-icon {
                    width:15px;
                    height:15px;
                    margin-left:-15px;
                    margin-right:15px;
                    margin-top:-4px;
                    display:inline-block;
                }
            }
            .group-accordian {
                position:relative;
                border:0px solid #009999;
                height:1px;
                overflow: hidden;
            }
        }
        
        .technologies-holder{
            position:absolute;
            top:0%;
            right:0%;

            margin: 0 10px 0 0;
            .promo-small{
                margin: 0;
            }
            @include break(990){
                margin: 0 20px 0 0;
            }
            @include break(500){
                float: right;
                position:relative;
            }
        }
        /* 
        .technologies-holder {
            float: right;
            margin: 0 10px 0 0;
            .promo-small{
                margin: 0;
            }
            @include break(990){
                margin: 0 20px 0 0;
            }
        }
         */        
        &:first-child{
            border-right: 0px solid $grey;
        }
        &:nth-child(2){
            /*border-left: 1px solid $grey;*/
            .print-table-th .col-left{
                padding: 0 0 0 10px;
                box-sizing: border-box;
                @include break (990){
                    padding: 0;
                }
            }
        }
        @include break($mobile){
            width: 100%;
            border: none;
            /*&:nth-child(2){
                 border: none;
            }*/
        }

        .sub-link:hover {
            background-color:#f2f2f2;
            display:block;
        }
        .pos-relative {
            position:relative;
            width:100%;
            height:100%;
        }
    }
    
    a{
        position:relative;
        display:inline-block;
        float:left;
        width:100%;
    }

    .result-category {
        a {
            position:relative;
            display:inherit;
            float:none;
            width:auto;
        }
    }
}

.group {
    .accordian_container {
            position:relative;
            overflow:hidden;
            height:1px;
            /*transition: height 0.5s ease;*/
            width:100%;
            border:0px solid $red;
    }
    .accordian_drop_fill_color {
        fill: #9c0;
        .outdoor &{
            fill: $outdoor;
        }
        .indoor &{
            fill: $indoor;
        }
        .dyesublimation &{
            fill: $dyesublimation;
        }
        .drawingoffice &{
            fill: $drawingoffice;
        }
        .hp &{
            fill: $hp;
        }
        .canon &{
            fill: $canon;
        }
    }
    .accordian_drop_path_minus, .accordian_drop_path_plus {
        fill: #fff;
    }
}