.detail{
    width: 990px;
    margin: 0 auto;
    
    @include break (990){
        width: 100%;
    }
    
    h3{
        float: left;
    }
    
    .promo-small{
        float: left;
        margin: 40px 0 0 0;
        
        @include break(990){
            margin: 40px 0 0 20px;
        }
    }
    
    .col-left{
        width: 200px;
        float: left;
        clear: left;
        height: 20px;
        
        @include break(990){
            font-weight: bold;
        }
    }
    
    .col-right{
        float: left;
        border-left: 1px solid $grey;
        padding: 0 0 0 30px;
        min-height: 20px;
        max-width: 600px;
        
        @include break(990){
            width: 100%;
            clear: left;
            border: none;
            padding: 0 20px 0 50px;
            box-sizing: border-box;
        }
    }
}