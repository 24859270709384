.canon-printers-table{
    
    width: 950px;
    margin: 0 auto;
    
    @include break(990){
        width: 100%;
    }

    .printer-item-cont {
        float:left;
        width:33.3333333%;
        box-sizing: border-box;
        margin-top:0px;
        @include break(640){
            width:100%;
        }
        .image-container {
            background-position:center center;
            background-size:contain;
            background-repeat: no-repeat;
            width:100%;
            height:230px;
            border-right-width:1px;
            border-right-color:$grey;
            border-right-style:solid;
            box-sizing: border-box;
            &.no-border {
                border-right-width:0px;
            }
        }

        h3 {
            border-bottom-width:1px;
            border-bottom-color:$grey;
            border-bottom-style:solid;
            border-right-width:1px;
            border-right-color:$grey;
            border-right-style:solid;
            padding-bottom:15px;
            padding-top:15px;
            padding-left:25px;
            margin-bottom:0; 
            margin-top:0;
            height:50px;
            &.no-border {
                border-right-width:0px;
            }
        }

        .details-container {
            border-right-width:1px;
            border-right-color:$grey;
            border-right-style:solid;

            border-bottom-width:1px;
            border-bottom-color:$grey;
            border-bottom-style:solid;

            box-sizing: border-box;
            &.no-border {
                border-right-width:0px;
            }
            p {
                margin:0 0 0 0;
                padding-top:15px;
                padding-bottom:15px;
                line-height:1.5;
                line-height:150%;
                min-height:66px;
            }
        }
    }

}


.printer-device-container {
    width:100%;
    height:350px;
    position:relative;
    @include break(640) {
        height:auto;
    }
    .image_container {
        width:100%;
        height:350px;
        background-position:center bottom;
        background-size:auto 80%;
        background-repeat: no-repeat;
        @include break(640){
            background-size:90% auto;
            height:190px;
        }
    }
    .hotspot-spot {
        position:absolute;
        width:17px;
        height:17px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        background-color:$indoor;
        overflow:visible;
        cursor:pointer;
        .hotspotextend {
            position:absolute;
            background-color:transparent;
            left:-20px;
            top:-20px;
            width:60px;
            height:60px;
            pointer-events: none;
        }
        &.left-spot {
            top:222px;
            left:210px;
        }
        &.right-spot {
            top:150px;
            right:230px;   
        }
        @include break(640){
            display:none;
        }
    }

    .image-spot {
        position:absolute;
        width:195px;
        height:195px;
        background-position:center center;
        background-repeat:no-repeat;
        background-size:cover;
        display:none;
        &.left-spot {
            left:55px;
            top:34px;
            background-image:url(../images/printer-spot-left.svg);
            img {
                width:155px;
                height:155px;
                padding:20px 0 0 20px;
            }
        }
        &.right-spot {
            right:40px;
            top:140px;
            background-image:url(../images/printer-spot-right.svg);
            img {
                width:155px;
                height:155px;
                padding:20px 0 0 20px;
            }
        }
        @include break(640){
            display:none;
        }
    }

    .print-arrow {
        width:50px;
        height:130px;
        position:absolute;
        top:60px;
        background-position:left center;
        background-repeat:no-repeat;
        background-size:33px 64px;
        cursor:pointer;
        &.arrow-left {
            left:0px;
            background-image:url(../images/printer-arrow-left.svg);
            @include break(640){
                left:5px;
            }
        }
        &.arrow-right {
            right:0px;
            background-image:url(../images/printer-arrow-right.svg);
            @include break(640){
                right:5px;
            }
        }
        @include break(640){
            width:30px;
            height:70px;
            top:90px;
            background-size:20px auto;
        }
    }

}

.plus_container {
    width:100%;
    height:47px;
    margin-top:15px;
    position:relative;
    .printer-nav-plus {
        position:absolute;
        left:141px;
        top:0px;
    }
}

#printer-relatedproducts-container {
    display:none;
    .rel-list-item {
        width:600px;
        a {
            color:$ppgrey;
        }
    }
}