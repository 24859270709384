.ink-table{
    max-width: 890px;
    // margin: 0 auto;
    
    @include break(990){
        width: 300px;
        margin: 0 auto;
    }
    .row{
        float: left;
        clear: left;
        width: 100%;
        box-sizing: border-box;
        margin: 0 0 0 50px;
        
        @include break(990){
            margin: 0;
        }
        
    }
    
    .col-3{
        width: 33%;
        float: left;
        min-width: 290px;
        // border-bottom: 2px solid $grey;
        // padding: 0 0 10px 0;
        
        .th{
            font-size: 21px;
            text-transform: uppercase;
            padding: 10px 0;
            border-bottom: 1px solid $grey;
            margin: 0 0 10px 0;
            .models{
                font-size: 15px;
            }
        }
        
        .td{
            border-left: 1px solid $grey;
            padding: 0 0 0 10px;
            width: 100%;
            float: left;
            clear: left;
            color: $ppgrey;
            &:hover{
                color: $hover;
            }
            .ink-code{
                width: 38%;
                float: left;
            }
            .ink-color{
                width: 46.5%;
                float: left;
            }
            
            
            @include break(990){
             padding: 0;
             border: none;   
            }
        }
        
        &:first-child, &:nth-child(4){
            .td{
                border: none;
            }
        }
        
        .outdoor &{
            color: $outdoor;
        }
        .indoor &{
            color: $indoor;
        }
        .dyesublimation &{
            color: $dyesublimation;
        }
        .drawingoffice &{
            color: $drawingoffice;
        }
        .hp &{
            color: $hp;
        }
    }
}