.consuables-table{
    width: 880px;
    margin: 0 auto;
    
    @include break ($mobile){
        width: 100%;
    }
    
    .col-2{
        width: 50%;
        min-width: 290px;
        float: left;
        box-sizing: border-box;
        
        @include break ($mobile){
            width: 100%;
        }
        
        h3{
            border-bottom: 1px solid $grey;
            padding: 0 0 20px 20px;
        }
        
        .consumable-item{
            border-bottom: 1px solid $grey;
            min-height: 25px;
            line-height: 25px;
            display: block;
            padding: 0 0 0 20px;
            margin: 0 20px 0 0;
            box-sizing: border-box;
            @include break ($mobile){
                margin: 0;
            }
            
            &:hover{
                color: $hover;
            }
        }
        
        .support-item{
            border-left: 1px solid $grey;
            padding: 0 20px 0 20px;
            float: left;
            @include break ($mobile){
                border: none;
            }
            
            &:first-child{
                margin: 10px 0 0 0;
            }
            
            .support-name{
                font-size: 21px;
                text-transform: uppercase;
                margin: 0 0 10px 0;
                .indoor &{
                    color: $indoor;
                }
                .outdoor &{
                    color: $outdoor;
                }
                .dyesublimation &{
                    color: $dyesublimation;
                }
                .drawingoffice &{
                    color: $drawingoffice;
                }
                .hp &{
                    color: $hp;
                }
            }
            
            hr{
                width: 90%;
                margin: 10px auto;
                float: none;
            }
        }
    }
}