.other-table{
    width: 890px;
    margin: 0 auto;
    
    @include break($mobile){
        width: 100%;
        
    }
    
    .other-product{
        width: 50%;
        float: left;
        // margin: 0 0 20px 0;
        border-bottom: 1px solid $grey;
        &.last-row{
            border: none;
        }
        
        .other-product-name{
            display: block;
            font-size: 21px;
            text-transform: uppercase;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid $grey;
            padding: 0 0 0 20px;
            transition: all 0.4s;
            .promo-small{
                margin: 10px 0 0 0;
                display: inline-block;
                float: none;
            }
            
            .outdoor &{
                color: $outdoor;
            }
            .indoor &{
                color: $indoor;
            }
            .dyesublimation &{
                color: $dyesublimation;
            }
            .drawingoffice &{
                color: $drawingoffice;
            }
            .hp &{
                color: $hp;
            }
            
            &:hover{
                color: $hover;
            }
        }
        
        .other-product-descriton{
            padding: 0px 20px 10px 20px;
            margin: 10px 0 10px 0;
            min-height:50px;
        }
        
        &:nth-child(even){
            
            .other-product-descriton{
                border-left: 1px solid $grey;
                @include break($mobile){
                    border: none;
                }
            }
            
        }
        
        @include break($mobile){
            width: 100%;
        }
        
    }
}