.hardware-table{
    width: 870px;
    margin: 20px auto;
    
    @include break ($mobile){
        width: 100%;
    }
    
    .col-3{
        width: 290px;
        float: left;
        
        @include break($mobile){
            width: 100%;
            clear: left;
            
            padding: 20px 0;
            border-bottom: 1px solid $grey;
        }
        
        .col-name{
            text-transform: uppercase;
            font-size: 21px;
            width: 100%;
            text-align: center;
            .outdoor &{
                color: $outdoor;
            }
            .indoor &{
                color: $indoor;
            }
            .dyesublimation &{
                color: $dyesublimation;
            }
            .drawingoffice &{
                color: $drawingoffice;
            }
            .hp &{
                color: $hp;
            }
        }
        
        .product-image{
            margin: 0 auto;
            display: block;
        }
        
        .product-name{
            h3{
                font-size: 17px;
                margin: 0;
                padding: 0 0 0 20px;
                
            }
            
            .sub-name{
                padding: 0 0 0 20px;
            }
            
            border-bottom: 1px solid $grey;
            padding: 10px 0;
            
            
            @include break($mobile){
                text-align: center;
                padding: 0 0 20px 0;
            }
        }
        
        .product-info{
            margin: 20px 0 0 0;
            padding: 0 0 0 20px;
            
            border-left: 1px solid $grey;
            &.no-border{
                border: none;
            }
            
            @include break($mobile){
                border: none;
            }
            
            ul{
                list-style: none;
                padding:10px 0 0 0;
                margin: 0;
                
                li{
                    padding: 8px 0 0 10px;
                    text-indent: -10px;
                    &:before{
                        content: "• ";
                        .outdoor &{
                            color: $outdoor;
                        }
                        .indoor &{
                            color: $indoor;
                        }
                        .dyesublimation &{
                            color: $dyesublimation;
                        }
                        .drawingoffice &{
                            color: $drawingoffice;
                        }
                        .hp &{
                            color: $hp;
                        }
                    }
                }
            }
            
            .pp-btn{
                margin: 20px auto 0 auto;
            }
        }
        .hardware-prod-info-cont {
            
        }
    }
}