.nav-main{
    position: absolute;
    right: 150px;
    top: 20px;
    font-size: 15px;
    
    &.mobile{
        // width: 100%;
        // height: 100%;
        // background: none;
        background: $grey;
        position: relative;
        top: -10px;
        left: 0;
        height: 0;
        ul{
            // display: none;
            pointer-events: all;
        } 
        
        &.open{
            background: $grey;
            
            ul{
                // display: block;
                li{
                    color: $white;
                    border-bottom: 1px solid $ppgrey;
                    opacity: 0;
                    visibility: hidden;
                    
                    &:first-child{
                        border-top: 1px solid $ppgrey;
                    }
                }
            }
        }
        
    }
    
    .hamburger{
        position: absolute;
        top: 15px;
        right: 22px;
        height: 40px;
        width: 40px;
        cursor:pointer;
        pointer-events: all;
        
        #icon-close{
            display: none;
        }
        
        &.open{
            #icon-hamburger{
                display: none;
            }
            #icon-close{
                display: block;
            }
        }
    }
    
    ul{
        list-style: none;
        padding: 0;
        margin: 0 0 0 0;
        @include break($mobile){
            margin: 40px 0 0 0;
        }
        li{
            margin: 3px 5px 0 0;
            float: left;
            display: block;
            height: 13px;
            line-height: 13px;
            box-sizing: border-box;
            padding: 0 5px;
            border-left: 1px solid $ppgrey;
            text-align: center;
            color: $ppgrey;
            text-transform: uppercase;
            &:first-child{
                margin: 0 3px 0 0;
                border-left: none;
            }
            
            @include break($mobile){
                clear: left;
                width: 100%;
                height: 30px;
                line-height: 30px;
                margin: 0;
                border: none;
            }
        }
    }
    
    .nav-main-item{
        color: $ppgrey;
        transition: color 0.4s;
        text-decoration: none;
        position: relative;
        
        &.active{
            color: $active; 
        }
        
        &:hover{
            color: $hover;
        }
    }
    
    .nav-main-home, .nav-main-search{
        width: 18px;
        height: 18px;
        margin-left:5px;
        display: block;
        &:hover{
            .nav-main-icon{
                fill: $hover;
            }
        }
    }
    
    // svg
    .nav-main-icon{
        fill: $red;
        transition: fill 0.4s;
    }
}