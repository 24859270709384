.row{
    width: 100%;
    float: left;
    clear: left;
    display: block;
    margin: 0 0 0 48px;
    min-width: 300px;
    
    @include break($mobile){
        margin: 0 0 20px 0;
        padding: 0 10px 10px 10px;
        border-bottom: 1px solid $grey;
        box-sizing: border-box;
    }
    
    h4{
        margin: 5px 0;
    }
    p{
        margin: 0;
        padding: 0;
        line-height: 18px;
    }
    
    .branch-detail{
        float: left;
        margin: 0 0 0 20px;
        padding: 0 0 0px 20px;
        border-left: 1px solid $grey;
        min-height: 140px;
        
        &:first-child{
            border: none;
            padding: 0;
            margin: 0;
        }
        
        &.branch-name{
            // width: 40%;
            min-width: 250px;
        }
        &.branch-address{
            // width: 15%;
            min-width: 150px;
        }
        &.map-link{
            border-left: none;
            padding: 0 20px 0 0;
            border-right: 1px solid $grey;
            margin: 0 20px 0 0;
            
            @include break ($mobile){
                border: none;
            }
            img{
                float: left;
                margin: 0 0 0 10px;
                mix-blend-mode: multiply;
            }
            span{
                float: left;
                clear: left;
                font-size: 12px;
            }
        }
        
        @include break($mobile){
            width: 100%;
            margin: 0;
            border: none;
            padding: 0;
        }
    }
}

.form-left{
    width: 310px;
    float: left;
    margin: 20px 0 40px 50px;
    @include break($mobile){
        margin: 20px 0;
    }
}

.form-right{
    float: left;
    margin: 20px 0 0 40px;
    width: 510px;
    
    @include break($mobile){
       margin: 20px 0;
       width: 100%; 
    }
    
    #btn-send{
        float: right;
        margin: 20px 0 0 0;
    }
}

.message{
    margin: 20px 50px;
    font-weight: bold;
}
    
textarea{
    width: 510px;
    height: 145px;
    background: url('../images/bg-textarea.png') no-repeat;
    background-size: contain;
    border: none;
    box-sizing: border-box;
    padding: 20px;
    @include break($mobile){
     width: 100%;
        width: 290px;
        margin: 0 0 10px 5px;
        height: 85px;
    }
}

.g-recaptcha{
    float: left;
    margin: 20px 0 0 0;
}