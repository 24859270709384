.wrapper-index{
    width: 100%;
    max-width: 1920px;
    height: 100%;
    background: url('../images/bg-home.jpg') no-repeat center -120px;
    float: left;
}

.home-icons{
    position: relative;
    height: 590px;
    
    @include break($mobile){
        height: auto;
        margin: 0 0 0 30px;
    }
}
.home-icon{
    display: block;
    position: absolute;
    
    
    &.icon-outdoor{
        width: 300px;
        height: 300px;
        left: 0;
        top:200px;
        @include break($mobile){
            position: relative;
            float: left;
            // clear: left;
            width: 150px;
            height: 150px;
            display: block;
            left: 0;
            top: 0;
        }
    }
    &.icon-indoor{
        left: 110px;
        top: 0px;
        width: 300px;
        height: 300px;
        @include break($mobile){
            position: relative;
            float: left;
            // clear: left;
            width: 150px;
            height: 150px;
            display: block;
            left: 0;
            top: 0;
        }
    }
    &.icon-dyesublimation{
        left: 270px;
        top: 200px;
        width: 300px;
        height: 300px;
        @include break($mobile){
            position: relative;
            float: left;
            // clear: left;
            width: 150px;
            height: 150px;
            display: block;
            left: 0;
            top: 0;
        }
    }
    &.icon-drawingoffice{
        left: 440px;
        top: 15px;
        width: 300px;
        height: 300px;
        @include break($mobile){
            position: relative;
            float: left;
            // clear: left;
            width: 150px;
            height: 150px;
            display: block;
            left: 0;
            top: 0;
        }
    }
    &.icon-hp{
        left: 580px;
        top: 220px;
        width: 300px;
        height: 300px;
        @include break($mobile){
            position: relative;
            float: left;
            // clear: left;
            width: 150px;
            height: 150px;
            display: block;
            left: 0;
            top: 0;
        }
    }
    &.icon-canon{
        right: -10px;
        top: 30px;
        width: 280px;
        height: 280px;
        @include break($mobile){
            position: relative;
            float: left;
            // clear: left;
            width: 150px;
            height: 150px;
            display: block;
            left: 0;
            top: 0px;
        }
    }
    
    
    
    
}