.category-icons-holder{
    float: left;
    clear: left;
    margin: 50px 475px 30px -475px;
    left: 50%;
    position: relative;
    width: 100%;
    
    @include break ($mobile){
        left: 0;
        margin: 50px 0;
        width: 100%;
    }

    .canon &{
        margin: 1px 475px 30px -475px;
    }
    
     &.mobile{
         margin: -20px 0 30px 0;
        .category-link-mobile{
            text-transform: uppercase;
            font-size: 18px;
            float:left;
            clear:left;
            margin: 5px 0 0 50px;
            text-decoration: underline;
            &.selected{
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
            }
           .outdoor &{
                color: $outdoor;
            }
            .indoor &{
                color: $indoor;
            }
            .dyesublimation &{
                color: $dyesublimation;
            }
            .drawingoffice &{
                color: $drawingoffice;
            }
            .hp &{
                color: $hp;
            }
            
            &:hover{
                color: $hover;
            }
        }
    }
}

.category-icon{    
    float: left;
    width: 209px;
    height: 209px;
    display: block;
    margin: 50px 0 0 0;
    
    &.selected{
        width: 318px;
        height: 317px;
        margin: 0;
    }
    
    .outdoor &{
        &.category-icon-ink{
            background: url('../images/icons/outdoor_media_ink.svg') no-repeat;
            &.selected{
                background: url('../images/icons/outdoor_media_ink_selected.svg') no-repeat; 
            }
        }
        &.category-icon-print{
            background: url('../images/icons/outdoor_media_print_media.svg') no-repeat;
            &.selected{
                background: url('../images/icons/outdoor_media_print_media_selected.svg') no-repeat;
            }
        }
        &.category-icon-hardware{
            background: url('../images/icons/outdoor_media_hardware.svg') no-repeat;
            &.selected{
                background: url('../images/icons/outdoor_media_hardware_selected.svg') no-repeat;
            }
        }
        &.category-icon-consumables{
            background: url('../images/icons/outdoor_media_consumables.svg') no-repeat;
            &.selected{
                background: url('../images/icons/outdoor_media_consumables_selected.svg') no-repeat;
            }
        }
    }
    
    .indoor &{
        &.category-icon-ink{
            background: url('../images/icons/indoor_media_ink.svg') no-repeat;
            &.selected{
                background: url('../images/icons/indoor_media_ink_selected.svg') no-repeat;
            }
        }
        &.category-icon-print{
            background: url('../images/icons/indoor_media_print_media.svg') no-repeat;
            &.selected{
                background: url('../images/icons/indoor_media_print_media_selected.svg') no-repeat;
            }
        }
        &.category-icon-hardware{
            background: url('../images/icons/indoor_media_hardware.svg') no-repeat;
            &.selected{
                background: url('../images/icons/indoor_media_hardware_selected.svg') no-repeat;
            }
        }
        &.category-icon-consumables{
            background: url('../images/icons/indoor_media_consumables.svg') no-repeat;
            &.selected{
                background: url('../images/icons/indoor_media_consumables_selected.svg') no-repeat;
            }
        }
    }
    
    .dyesublimation &{
        &.category-icon-ink{
            background: url('../images/icons/dye_sub_ink.svg') no-repeat;
            &.selected{
                background: url('../images/icons/dye_sub_ink_selected.svg') no-repeat;
            }
        }
        &.category-icon-print{
            background: url('../images/icons/dye_sub_print_media.svg') no-repeat;
            &.selected{
                background: url('../images/icons/dye_sub_print_media_selected.svg') no-repeat;
            }
        }
        &.category-icon-hardware{
            background: url('../images/icons/dye_sub_hardware.svg') no-repeat;
            &.selected{
                background: url('../images/icons/dye_sub_hardware_selected.svg') no-repeat;
            }
        }
        &.category-icon-consumables{
            background: url('../images/icons/dye_sub_consumables.svg') no-repeat;
            &.selected{
                background: url('../images/icons/dye_sub_consumables_selected.svg') no-repeat;
            }
        }
    }
    
    .drawingoffice &{
        &.category-icon-wetstrength{
            background: url('../images/icons/office_wet_books_medical.svg') no-repeat;
            margin-left: 20px;
            &.selected{
                background: url('../images/icons/office_wet_books_medical_selected.svg') no-repeat;
            }
        }
        &.category-icon-print{
            background: url('../images/icons/office_print_media.svg') no-repeat;
            margin-left: 20px;
            &.selected{
                background: url('../images/icons/office_print_media_selected.svg') no-repeat;
                margin-top: 0;
            }
        }
        &.category-icon-drafting{
            background: url('../images/icons/office_drafting_tracing.svg') no-repeat;
            margin-left: 20px;  
            &.selected{
                background: url('../images/icons/office_drafting_tracing_selected.svg') no-repeat;
            }
        }
        &.category-icon-planfiling{
            background: url('../images/icons/office_plan_filing.svg') no-repeat;
            clear: left;
            margin-top: -10px;
            margin-left: 170px;
            &.selected{
                background: url('../images/icons/office_plan_filing_selected.svg') no-repeat;
            }
        }
        &.category-icon-drawingboards{
            background: url('../images/icons/office_drawing_accessories.svg') no-repeat;
            margin: -10px 0 0 20px;
            &.selected{
                background: url('../images/icons/office_drawing_accessories_selected.svg') no-repeat;
            }
        }
        &.category-icon-officesupplies{
            background: url('../images/icons/office_office_supplies.svg') no-repeat;
            margin: -10px 0 0 20px;
            &.selected{
                background: url('../images/icons/office_office_supplies_selected.svg') no-repeat;
            }
        }
    }
    .hp &{
        &.category-icon-graphical{
            background: url('../images/icons/hp_graphical_media.svg') no-repeat;
            width:26%;
            margin-left:10%;
            &.selected{
                background: url('../images/icons/hp_graphical_media_selected.svg') no-repeat;
            }
        }
        &.category-icon-technical{
            background: url('../images/icons/hp_technical_media.svg') no-repeat;
           width:26%;
           margin-left:0%;
           margin-right:0%;
            &.selected{
                background: url('../images/icons/hp_technical_media_selected.svg') no-repeat;
            }
        }
        &.category-icon-latex{
            background: url('../images/icons/hp_latex_media.svg') no-repeat;
           width:26%;
            &.selected{
                background: url('../images/icons/hp_latex_media_selected.svg') no-repeat;
            }
        }
    }

    .canon &{
        &.category-icon-graphical-photo{
            background: url('../images/icons/canon_graphical.svg') no-repeat;
            &.selected{
                background: url('../images/icons/canon_graphical_selected.svg') no-repeat;
            }
        }
        &.category-icon-technical-cad{
            margin-left:280px;
            background: url('../images/icons/canon_technical.svg') no-repeat;
            &.selected{
                margin-left:230px;
                background: url('../images/icons/canon_technical_selected.svg') no-repeat;
            }
        }
    }
}

@import 'landing_pages';