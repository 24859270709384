$black: #000000;
$white: #FFFFFF;

$red: #84282B;
$active: #AB2725;
$hover: #DA4640;
$grey: #CCCCCC;

$lightGrey: transparent;

$ppgrey: #555D60;
$outdoor: #006690;
$indoor: #98C93C;
$dyesublimation: #DB477D;
$drawingoffice: #F37C20;
$hp: #00A3E3;
$canon: #e8352f;
