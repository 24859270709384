.landing-icons{
    width: 100%;
    
    .outdoor &{ height: 660px; }
    .indoor &{ height: 570px; }
    .dyesublimation &{ height: 600px; }
    .drawingoffice &{ height: 660px; }
    .hp &{ height: 590px; }
    
    position: relative;
    
    &.mobile{
        height: auto;
        
        a{
            float: left;
            clear: left;
            margin: 0 0 0 10px;
            font-size: 18px;
            line-height: 20px; 
            
        }
    }
    
    .landing-icon{
        display: block;
        position: absolute;
    
        &.landing-icon-ink{
            .outdoor &{
                background: url('../images/icons/outdoor_media_ink.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 35px;
                left: 80px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/outdoor_media_ink_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            
            .indoor &{
                background: url('../images/icons/indoor_media_ink.svg') no-repeat;
                background-size: contain;
                width: 235px;
                height: 235px;
                top: 5px;
                left: 380px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/indoor_media_ink_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            
            .dyesublimation &{
                background: url('../images/icons/dye_sub_ink.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 35px;
                left: 80px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/dye_sub_ink_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        &.landing-icon-print{
            .outdoor &{
                background: url('../images/icons/outdoor_media_print_media.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 15px;
                left: 380px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/outdoor_media_print_media_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            
            .indoor &{
                background: url('../images/icons/indoor_media_print_media.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 20px;
                left: 640px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/indoor_media_print_media_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            .dyesublimation &{
                background: url('../images/icons/dye_sub_print_media.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 15px;
                left: 380px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/dye_sub_print_media_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            .drawingoffice &{
                background: url('../images/icons/office_print_media.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 290px;
                left: 10px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/office_print_media_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        &.landing-icon-hardware{
            .outdoor &{
                background: url('../images/icons/outdoor_media_hardware.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 406px;
                left: 610px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/outdoor_media_hardware_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            
            .indoor &{
                background: url('../images/icons/indoor_media_hardware.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 280px;
                left: 650px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/indoor_media_hardware_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            .dyesublimation &{
                background: url('../images/icons/dye_sub_hardware.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 350px;
                left: 50px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/dye_sub_hardware_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        &.landing-icon-consumables{
            .outdoor &{
                background: url('../images/icons/outdoor_media_consumables.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 145px;
                left: 635px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/outdoor_media_consumables_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            
            .indoor &{
                background: url('../images/icons/indoor_media_consumables.svg') no-repeat;
                background-size: contain;
                width: 280px;
                height: 280px;
                top: 300px;
                left: 360px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/indoor_media_consumables_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
            .dyesublimation &{
                background: url('../images/icons/dye_sub_consumables.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 145px;
                left: 635px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/dye_sub_consumables_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        &.landing-icon-graphical{
            .hp &{
                background: url('../images/icons/hp_graphical_media.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 170px;
                left: 30px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/hp_graphical_media_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        &.landing-icon-technical{
            .hp &{
                background: url('../images/icons/hp_technical_media.svg') no-repeat ;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 25px;
                left: 300px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/hp_technical_media_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        &.landing-icon-latex{
            .hp &{
                background: url('../images/icons/hp_latex_media.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 370px;
                left: 225px; 
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/hp_latex_media_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        &.landing-icon-wet{
            .drawingoffice &{
                background: url('../images/icons/office_wet_books_medical.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 45px;
                left: 40px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/office_wet_books_medical_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        &.landing-icon-drafting{
            .drawingoffice &{
                background: url('../images/icons/office_drafting_tracing.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 395px;
                left: 240px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/office_drafting_tracing_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        &.landing-icon-office{
            .drawingoffice &{
                background: url('../images/icons/office_office_supplies.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 395px;
                left: 500px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/office_office_supplies_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        &.landing-icon-planfiling{
            .drawingoffice &{
                background: url('../images/icons/office_plan_filing.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 290px;
                left: 700px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/office_plan_filing_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        &.landing-icon-drawing{
            .drawingoffice &{
                background: url('../images/icons/office_drawing_accessories.svg') no-repeat;
                background-size: contain;
                width: 250px;
                height: 250px;
                top: 40px;
                left: 720px;
                transition: background 0.4s;
                &:hover{
                    background: url('../images/icons/office_drawing_accessories_selected.svg') no-repeat;
                    background-size: contain;
                }
            }
        }
        
        
        
        &.landing-icon-main{
            .outdoor &{
                width: 440px;
                height: 440px;
                background: url('../images/icons/outdoor-media.svg') no-repeat;
                // background-size: contain;
                top: 210px; 
                left: 160px;
            }
            
            .indoor &{
                width: 420px;
                height: 540px;
                background: url('../images/icons/indoor-media.svg') no-repeat;
                top: 20px;
                left: 0px;
            }
            
            .dyesublimation &{
                width: 440px;
                height: 440px;
                background: url('../images/icons/dye-sublimation.svg') no-repeat;
                top: 210px; 
                left: 160px;
            }
            
            .drawingoffice &{
                width: 480px;
                height: 480px;
                background: url('../images/icons/drawing_office.svg') no-repeat;
                top: 20px;
                left: 270px;
            }
            
            .hp &{
                width: 500px;
                height: 560px;
                background: url('../images/icons/hp.svg') no-repeat;
                top: 100px;
                left: 440px;
            }
        }
    }
}