.accounts-downloads{
    
    .btn-download{
        margin: 70px 0 0 0;
        display: block;
        img{
            display: inline-block;
            margin: 0 10px 0 0;
        }
    }
}