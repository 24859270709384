/**
 * breakpoints for media queries
 * usage example: 
 * .section{
 *   width: 100%;
 *   @include bp($mobile){
 *     width: 50%;
 *   }
 * }
 */

// $smalldesktop:"(max-width: 1279px)";  // fit into 1024px
// $tablet:      "(max-width: 1023px)";  // fit into 720px
// $mobile_wide: "(max-width: 800px)";   // fit into 480px
// $mobile:      "(max-width: 480px)";   // fit into 320px

$mobile: 990; // mobile stuff for Paper Plot

// @mixin bp($size){
//    @media screen and #{$size} { @content; }
// }

@mixin break($size){
    @media screen and (max-width: #{$size}px){ @content; }
}
