.search-form{
    
    margin: 0 0 0 50px;
    
    h3, p{
        padding: 0;
    }
    
    ul{
        padding: 0;
        margin: 0;
    }
    
    .table-key{
        margin: 0;
    }
    
    .pp-btn{
        margin: 30px 0 0 0;
    }
    
    select{
        width: 200px;
        height: 42px;
        border-radius: 7px;
        font-family: calibri;
        box-sizing: border-box;
        padding: 0 10px;
        line-height: 40px;
        font-size: 15px;
        margin: 0 0 0 20px;
    }
    
    
    
    .print-tech-icon{
        
        cursor: pointer;
        -ms-transition: background 0.4s;
        -webkit-transition: background 0.4s;
        transition: background 0.4s;
        &.deselected{
            background-color: $grey;
        }
    }
    
    .tech-label {
        cursor:pointer;
        width:200px;
    }
    
    .clear_search_btn {
        position:relative;
        float:right;
        margin-right:15px;
        margin-top:10px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        border:1px solid $grey;
        background-color: $grey;
        z-index: 100;
        p {
            font-size:13px;
            text-align:right;
            padding:3px 15px;
            margin:0px;
            color:#FFFFFF;
            text-transform: uppercase;
            a {
                color:#FFFFFF;
                font-weight:bold;
                text-transform: uppercase;
            }
        }
        
    }
}

.search-results{
    width: 890px;
    margin: 30px auto 0 auto;
    
    @include break (990){
        width: 90%;
    }
    
    hr{
        display: block;
        float: left;
        clear: left;
        height: 1px;
        background: $grey;
        width: 100%;
        box-sizing: border-box;
        margin: 20px 0 20px 0;
    }
    
    .result-category{
        // padding: 20px 0;
        
        
        a{
            color: $ppgrey;
        }
        
        &.outdoor{
            .cat-col{
                color: $outdoor;
            }  
            
            .left{
                // width: 100%;
                height: 250px;
                background: url('../images/icons/home_outdoor_media.svg') no-repeat;
                background-size: 250px 250px;
                background-position: top center;
            }
            
        } 
        &.indoor{
            .cat-col{
                color: $indoor;
            }
            
            .left{
                height: 250px;
                background: url('../images/icons/home_indoor_media.svg') no-repeat;
                background-size: 250px 250px;
                background-position: top center;
            }
        }
        &.dyesublimation{
             .cat-col{
                color: $dyesublimation;
            }
            
            .left{
                height: 250px;
                background: url('../images/icons/home_dye_sublimation.svg') no-repeat;
                background-size: 250px 250px;
                background-position: top center;
            }
        }
        &.drawingoffice {
            .cat-col{
                color: $drawingoffice;
            }
            
            .left{
                height: 250px;
                background: url('../images/icons/home_drawing_office.svg') no-repeat;
                background-size: 250px 250px;
                background-position: top center;
            }
        }
        &.hp {
            .cat-col{
                color: $hp;
            }
            
            .left{
                height: 250px;
                background: url('../images/icons/home_hp.svg') no-repeat;
                background-size: 250px 250px;
                background-position: top center;
            }
        }
        .left{
            width: 30%;
            float: left;
            @include break(990){
                width: 100%;
            }
        }
        
        .right{
            float: left;
            border-left: 1px solid $grey;
            width: 70%;
            box-sizing: border-box;
            padding: 0 0 0 20px;

            
            @include break(990){
                width: 100%;
                border: none;
            }
              
            .result-group{
                width: 100%;
                
                .group-name, .product-name{
                    font-size: 21px;
                    text-transform: uppercase;
                    line-height: 23px;
                    margin: 5px 0;
                }
                
                .product-name{
                    margin: 10px 0 0 0;
                }
                
                .product-subname{
                    font-size: 15px;
                    line-height: 15px;
                    .outdoor &{
                        color: $outdoor;
                    }
                    .indoor &{
                        color: $indoor;
                    }
                    .dyesublimation &{
                        color: $dyesublimation;
                    }
                    .drawingoffice &{
                        color: $drawingoffice;
                    }
                    .hp &{
                        color: $hp;
                    }
                }
                
                .product{
                    display: block;
                    height:25px;
                    line-height: 25px;
                    width: 100%;
                    float: left;
                    clear: left;
                    font-size: 15px;
                    text-transform: uppercase;
                    position: relative;
                    &:hover{
                        color: $hover;
                    }
                    &.first{
                        margin: 5px 0 0 0;
                    }
                    
                    .technologies-holder{
                        float: right;
                        position: absolute;
                        right: 0;
                    }
                    
                    .full-row{
                        display: inline-block;
                    }
                }
                
                .promo-small{
                    margin: 0 0 0 5px;
                }
                
                .product-left{
                    width: 28%;
                    float: left;
                }
                
                .product-right{
                    float: left;
                    border-left: 1px solid $grey;
                    padding: 0 0 0 20px;
                    
                    &.noborder{
                        border: none;
                        padding: 0;
                    }
                }
                
                .sub-product{
                    WIDTH: 100%;
                    float: left;
                    clear: left;
                    .product-right{
                        font-size: 13px;
                        padding: 0 0 0 60px;
                    }
                    &.first{
                        margin: 5px 0 0 0;
                    }
                }
                
            }   
        }
        
    }
}

.other-product{
    margin: 10px 0 0 0;
}

.other-product-name{
    font-weight: bold;
}

