.nav-sections{
    pointer-events: none;
    width: 990px;
    height: 196px;
    margin: 30px 0 0 0;
    background: url('../images/header-bg.png') no-repeat;
    float: left;
    position: relative;

    &.printer-page {
        background: url('../images/printer-nav-bg.png') no-repeat;
        background-position:center top;
        height:100px;
        margin: -10px 0 0 0;
    }
    
    a{
        text-decoration: none;
        color: $ppgrey;
        display:block;
    }
    
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            // float: left;
        }
    }
    
    .nav-sections-btn{
        pointer-events: all;
        position: absolute;
        // border: 1px solid red;
        cursor: pointer;
        width: 200px;
        height: 75px;
        top:18px;
        z-index: 1;
        &.print-page-btn1 {
            left:81px;
        }
        &.print-page-btn2 {
            left:383px;
        }
        &.print-page-btn3 {
            left:680px;
        }
        p{
            pointer-events: none;
            margin: 2px 0 0 0px;
            font-size: 11px;
            line-height: 12px;
            text-transform: uppercase;
        }
        
        .dots{
            pointer-events: none;
            width: 60px;
            height: 8px;
            position: absolute;
            top: -2px;
            left: 110px;
        }
        
        .dd-arrow{
            pointer-events: none;
            width: 76px;
            height: 40px;
            transition: background-position 0.5s;
            position: absolute;
            top: 41px;
            left: 60px;
        }
        
        .arr{
            pointer-events: none;
            position: absolute;
            top: 10px;
            right: 0px;
        }
        
        &.outdoor{
            left: 2px;
            
            .dd-arrow{
                background: url('../images/nav-ddarrow-outdoor.png') no-repeat 0 -7px;
            }
        }
        &.indoor{
            left: 202px;
            
            .dd-arrow{
                background: url('../images/nav-ddarrow-indoor.png') no-repeat 0 -7px;
            }
        }
        &.dyesublimation{
            left: 402px;
            
            .dd-arrow{
                background: url('../images/nav-ddarrow-dyesublimation.png') no-repeat 0 -7px;
            }
        }
        &.drawingoffice{
            left: 602px;
            
            .dd-arrow{
                background: url('../images/nav-ddarrow-drawingoffice.png') no-repeat 0 -7px;
            }
        }
        &.hp{
            left: 802px;
            
            .dd-arrow{
                background: url('../images/nav-ddarrow-hp.png') no-repeat 0 -7px;
            }
        }
        
        &:hover{
            .dd-arrow{
                background-position: 0 -3px;
            }
        }
    }
    
    .nav-sections-subnav{
        pointer-events: all;
        $gr1: #f7f7f7;
        $gr2: #e7e8e9;
        background: $gr1;/* For browsers that do not support gradients */
        background: -webkit-linear-gradient($gr1, $gr2); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient($gr1, $gr2); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient($gr1, $gr2); /* For Firefox 3.6 to 15 */
        background: linear-gradient($gr1, $gr2); /* Standard syntax */
        padding: 23px 30px 17px 30px;
        border-radius: 0 0 10px 10px;
        position: absolute;
        top: 75px;
        z-index: -1;
        
        
        &#subnav-outdoor{
            left: 14px;
            // display: none;
            .subnav-link{
                &.active{
                    color: $outdoor;
                }
            }
        }
        &#subnav-indoor{
            left: 200px;
            // display: none;
            .subnav-link{
                &.active{
                    color: $indoor;
                }
            }
        }
        &#subnav-dyesublimation{
            left: 405px;
            // display: none;
            .subnav-link{
                &.active{
                    color: $dyesublimation;
                }
            }
        }
        &#subnav-drawingoffice{
            left: 300px;
            width: 540px;
            // display: none;
            .subnav-link{
                &.active{
                    color: $drawingoffice;
                }
            }
        }
        &#subnav-hp{
            left: 580px;
            // width: 540px;
            // display: none;
            .subnav-link{
                &.active{
                    color: $hp;
                }
            }
        }
        
        .subnav-link{
            color: $ppgrey;
            text-transform: uppercase;
            font-size: 14px;
            text-decoration: none;
            box-sizing: border-box;
            padding: 0 5px;
            line-height: 14px;
            height: 14px;
            border-left: 1px solid $ppgrey;
            transition: color 0.4s;
            display: block;
            float: left;
            margin: 5px 0 0 0;
            &:first-child{
                border-left: none;
            }
            &:hover{
                color: $hover;
            }
            
            &.nopipe{
                // float: left;
                // clear: left;
                border-left: none;
            }
        }
    }
    
    
    
}