#btn-brochure{
    margin: 0 auto -30px auto;
    width: 280px;
    height: 150px;
    display: none;
    
    .outdoor &{
        display: block;
        background: url('../images/btn-brochure-outdoor.png') no-repeat;
    }
    .indoor &{
        display: block;
        background: url('../images/btn-brochure-indoor.png') no-repeat;
    }
    .dyesublimation &{
        display: block;
        background: url('../images/btn-brochure-dyesublimation.png') no-repeat;
    }
    .drawingoffice &{
        display: block;
        background: url('../images/btn-brochure-drawingoffice.png') no-repeat;
    }
    .hp &{
        display: block;
        background: url('../images/btn-brochure-hp.png') no-repeat;
    }
}
footer{
    background: url('../images/footer-bg.png') no-repeat;
    width: 990px;
    height: 120px;
    overflow: visible;
    
    margin: 30px auto 0 auto;
    position: relative;
    
    @include break($mobile){
        // display: none;
        width: 100%;
        background-image: none;
        background: $white;
        border-top: 1px solid $ppgrey;
        height: auto;
        
    }
    
    .footer-nav{
        float: left;
        margin: 30px 0 0 20px;
        @include break($mobile){
            margin: 0 0 10px 10px;   
        }
        
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            
            li{
                margin: 3px 5px 0 0;
                float: left;
                display: block;
                height: 13px;
                line-height: 13px;
                box-sizing: border-box;
                padding: 0 5px;
                border-left: 1px solid $ppgrey;
                text-align: center;
                color: $ppgrey;
                text-transform: uppercase;
                &:first-child{
                    margin: 0 3px 0 0;
                    border-left: none;
                }
                @include break($mobile){
                    float: left;
                    clear: left;
                    border: none;
                }
            }
        }
    }
    
    .nav-footer-item{
        color: $ppgrey;
        transition: color 0.4s;
        text-decoration: none;
        
        &:hover{
            color: $hover;
        }
    }
    
    .nav-footer-home{
        width: 18px;
        height: 18px;
        display: block;
        &:hover{
            .nav-main-icon{
                fill: $hover;
            }
        }
        @include break($mobile){
            display: none;
        }
    }
    
    // svg
    .nav-main-icon{
        fill: $red;
        transition: fill 0.4s;
    }
    
    .poweredby{
        position: absolute;
        right: 180px;
        top: 30px;
        
        @include break($mobile){
            display: none;
        }
    }
    
    .announcements{
        position: absolute;
        top: -40px;
        right: 10px;
        width: 170px;
        height: 170px;
        
        @include break($mobile){
           width: 50px;
           height: 50%; 
           top: 10px;
           
        }
        
        .announce{
            // display: none;
        }
    }

}