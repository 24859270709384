.header{
    width: 990px;
    // margin: 0 auto;
    margin: 0 0 0 -495px;
    left: 50%;
    box-sizing: border-box;
    padding: 10px 0 0 0;
    position: absolute;
    z-index: 99;
    pointer-events: none;
    
    a{
        pointer-events: all;
        position: relative;
    }
    
    .logo-main{
        float: left;
    }
    
    .logo-bidvest{
        float: right;
    }
    
    @include break($mobile){
        width: 100%;
        margin: 0;
        left: 0;
        .logo-main{
            margin: 10px;
        }
        
        .logo-bidvest{
            margin: 10px 60px 10px 10px ;
        }
    }
    
    @include break(400){
        .logo-main{
            img{
                width: 120px;
            }
        }
        .logo-bidvest{
            img{
                width: 90px;
            }
        }
    }
}

@import 'header/_nav-main';
@import 'header/_nav-sections';