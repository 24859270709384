.print-tech-icon{
    width: 19px;
    height: 15px;
    line-height: 15px;
    border-radius: 5px;
    color: $white;
    font-size: 10px;
    text-align: center;
    margin: 0;
    display: inline-block;
}

.print-tech-es{
    background: $outdoor;
    &:before{
        content: 'ES';
    }
}

.print-tech-d{
    background: $indoor;
    &:before{
        content: 'D';
    }
}

.print-tech-p{
    background: $indoor;
    &:before{
        content: 'P';
    }
}

.print-tech-c{
    background: $indoor;
    &:before{
        content: 'C';
    }
}

.print-tech-s{
    background: $outdoor;
    &:before{
        content: 'S';
    }
}

.print-tech-uv{
    background: $outdoor;
    &:before{
        content: 'UV';
    }
}

.print-tech-l{
    background: $hp;
    &:before{
        content: 'L';
    }
}

.print-tech-pw{
    background: #99c848;
    &:before{
        content: 'PW';
    }
}

.print-tech-uvg{
    background: #0e678f;
    &:before{
        content: 'UVG';
    }
}


.print-tech-ppc{
    background: $drawingoffice;
    &:before{
        content: 'PPC';
    }
}
.print-tech-ds{
    background: $dyesublimation;
    &:before{
        content: 'DS';
    }
}