// .col-holder{
//     display: flex;
//     flex-flow: row column;
//     -ms-flex-flow: row column;
//     -webkit-flex-flow: row column;
    
    
// }
// .col{
//     float: left;
//     width: 435px;
//     flex: 1 1 100%;
//     -ms-flex: 1 1 100%;
//     -webkit-flex: 1 1 100%;
    
//     ul{
//         margin: 0 0 0 20px;
//         padding: 0;
//     }
    
//     p{
//         margin: 0;
//         padding: 0;
        
//     }
// }
// .col-left{
//     margin: 0 0 0 50px;
// }
// .col-right{
// }
// .line{
//     width: 1px;
//     // height: 100%;
//     flex: -1 1 100%;
//     -ms-flex: -1 1 100%;
//     -webkit-flex: -1 1 100%;
//     background: $grey;
//     float: left;
//     margin: 0 20px; 
// }