.promo-small{
    width: 16px;
    height: 16px;
    background: url('../images/icon-promo-small.png') no-repeat;
    background-size: contain;
    float:right;
    margin: 0 25px 0 0;
    &.tooltip_a {
        float:right;
    }
}

.table-key{
    margin: 0 0 25px 0;
    ul{
        list-style: none;
        padding: 0 0 0 20px;
        
        li{
            display: inline-block;
            text-transform: uppercase;
            margin: 5px 20px 0 0;
            width: 180px;
            .print-tech-icon{
                margin: 0 5px;
            }
            .promo-small{
                float: left;
                margin: 0 5px;
            }
            &:nth-child(7){
                clear: left;
            }
        }
    }
}

@import 'ink_table';
@import 'detail_page';
@import 'print_table';
@import 'hardware_table';
@import 'consumables_table';
@import 'other_table';
@import 'icons';
@import 'canon_printers';